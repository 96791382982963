import { useAuthUser } from '~/composables/auth/useAuthUser'
import { useAuthCookie } from '~/composables/auth/useAuthCookie'

export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const user = await useAuthUser()
  const token = await useAuthCookie()
  // if (process.server) return;
  if (user.value) {
    if (_to.path === '/login' || _to.path === '/')
      return navigateTo('/branches')
  }
  if (!token.value)
    return navigateTo('/login')
  if (!user.value)
    return navigateTo('/login')
})
